import React, { useState } from 'react';
import './DurationField.css';
import { Box, TextField } from '@mui/material';
import { withLeadingZero } from '../shared/utils';

interface DurationFieldProps {
  value: string;
  setFieldValue: (field: string, value: string) => void;
  fieldName: string;
}

export function DurationField({value, setFieldValue, fieldName}: DurationFieldProps) {
  const [propHours, propMinutes] = value?.split(':') ?? '01:00';
  const [hours, setHours] = useState(+propHours);
  const [minutes, setMinutes] = useState(+propMinutes);

  const handleHoursChange = (value: string) => {
    setHours(+value);
    setFieldValue(fieldName, `${withLeadingZero(+value)}:${withLeadingZero(minutes)}`);
  };

  const handleMinutesChange = (value: string) => {
    setMinutes(+value);
    setFieldValue(fieldName, `${withLeadingZero(hours)}:${withLeadingZero(+value)}`);
  };

  return (
    <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
    <TextField
      id="duration-hours"
      name="duration-hours"
      label="Horas"
      type="number"
      inputProps={{
        minLength: 0,
        maxLength: 24,
        step: "1"
      }}
      value={hours}
      onChange={(e) => handleHoursChange(e.target.value)}
      size="small"
      error={hours < 0 || hours > 24}
    />
    <span>:</span>
    <TextField
        id="duration-minutes"
        name="duration-minutes"
        label="Minutos"
        type="number"
        inputProps={{
          minLength: 0,
          maxLength: 55,
          step: "5"
        }}
        value={minutes}
        onChange={(e) => handleMinutesChange(e.target.value)}
        size="small"
        error={minutes < 0 || minutes > 55}
      />
  </Box>

  );
}

export default DurationField;