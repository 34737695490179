import React, { useState } from 'react';
import { Button, Box, Typography, Paper, Divider } from '@mui/material';
import './Subscription.css';
import { AuthData, StoreData, UserFreeTrialData } from '../shared/types';
import { cancelPaypalSubscription, subscribe } from '../api';
import EventIcon from '@mui/icons-material/Event';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';
import TextBodyTwo from '../components/TextBodyTwo';
import { getCurrentMonthName, getLocaleFormattedDate, getLocaleFromStoreData } from '../shared/utils';
import { useGetDeliveriesForCurrentMonthCount } from '../shared/use-get-deliveries-for-month';

export interface SubscriptionProps {
  storeData: StoreData | undefined;
  subscriptions: any;
  userFreeTrial: UserFreeTrialData | undefined;
  authData: AuthData | {};
}

export function Subscription({ storeData, subscriptions, authData }: SubscriptionProps) {
  const [error, setError] = useState('');
  const [initPointLink, setInitPointLink] = useState('');
  const navigate = useNavigate();
  const scheduledDeliveriesMonthCount = useGetDeliveriesForCurrentMonthCount();
  const [loading, setLoading] = useState(false);

  const onSubscribeMobbex = () => {
    window.open(
      'https://mobbex.com/p/subscriptions/subscribe/8XNXEE5RTZ9TUIZEXN',
      '_blank'
    );
  };

  const onCancelPaypalSubscription = () => {
    if (window.confirm('Estás seguro de querer cancelar tu subscripción de Paypal?')) {
      cancelPaypalSubscription().then(() => {
        window.location.href = window.origin;
      })
    }
  };

  const onSubscribe = () => {
    if (!storeData) {
      navigate('/');
      return;
    }
    const paypalSubscription = subscriptions.find((sub: any) => sub.provider === 'paypal');
    if (paypalSubscription?.link) {
      window.open(paypalSubscription.link, '_blank');
      return;
    }
    setLoading(true);
    setError('');
    subscribe()
      .then((res) => {
        if (res.statusCode === 400) {
          setError(res.message);
          return;
        }
        if (res.link){
          window.open(res.link, '_blank');
          setInitPointLink(res.link);
          return;
        }
        setError('Error de init_point, contacte a soporte');
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  };
  return (
    <Box
      sx={{
        maxWidth: 500,
        width: '100%',
        border: '4px solid #1976d2',
        margin: '0 auto',
        borderRadius: '16px',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 8,
        paddingBottom: '24px',
        backgroundColor: 'white'
      }}
    >
      <Typography variant="h6" component="h6" sx={{ color: '#1976d2', marginTop: '40px' }}>
        {subscriptions?.length ? 'Mi Subscripción' : 'Subscribirse'}
      </Typography>
      <img src="./Datepicker_white.svg" className="login-logo" alt="logo" />
      {subscriptions?.length ? (
        <>
          {subscriptions.map((subscription: {id: string, name: string, created: string, provider: 'mp' | 'mobbex' | 'paypal', link: string}) => (
              <Paper
                key={subscription.id}
                elevation={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  alignItems: 'flex-start',
                  padding: '16px',
                  maxWidth: '300px',
                  width: '100%',
                  overflowX: 'hidden',
                  margin: '4px 0',
                  color: 'white',
                  backgroundColor: '#1976D2'
                }}
              >
              <span style={{display: 'flex', alignItems: 'center'}}>
                {subscription.provider === 'mp' && <img src="./mp_logo.png" className='payment-provider-logo_big' alt="Mercado Pago" />}
                {subscription.provider === 'mobbex' && <img src="./mobbex_logo.png" className='payment-provider-logo_big' alt="Mobbex" />}
                {subscription.provider === 'paypal' && <img src="./paypal_logo.png" className='payment-provider-logo_big' alt="Paypal" />}
                &nbsp;{subscription.name}
              </span>
              <span style={{display: 'flex', alignItems: 'center'}}>
                <EventIcon />
                &nbsp;Fecha de inicio:{' '}
                {getLocaleFormattedDate(subscription.created, getLocaleFromStoreData(storeData))}
              </span>
              {
                subscription.provider === 'paypal'
                  ? subscription.link
                    ?
                      <Box sx={{display: 'flex', alignItems: 'center', gap: '4px', color: 'yellow'}}>
                        <ShoppingCartCheckoutIcon />
                        <a href={subscription.link} target="_blank" rel="noreferrer" style={{color: 'inherit'}}>
                          Completar la subscripción
                        </a>
                      </Box>
                    :
                      <Button variant="contained" color="error" size="small" onClick={onCancelPaypalSubscription} sx={{mx: 'auto'}}>
                        cancelar
                      </Button>
                : null
              }
              </Paper>
          ))}
          {
            subscriptions.some((sub: any) => sub.provider === 'mp') && <Button
              color="primary"
              variant="outlined"
              onClick={() => window.open('https://www.mercadopago.com.ar/subscriptions', '_blank')}
              sx={{ marginTop: '16px' }}
            >
              Gestionar subscripción&nbsp;<img src="./mp_logo.png" className='payment-provider-logo' alt="Mercado Pago" />
            </Button>
          }
          {
            subscriptions.some((sub: any) => sub.provider === 'mobbex') &&
            <TextBodyTwo>
              <p>Para cancelar la subscripción o cambiar el método de pago, o por cualquier otra consulta, escribinos a contact@smiledatepicker.com</p>
              <p>Alternativamente el email original de confirmación de subscripción, contiene un link para gestionar la misma.</p>
            </TextBodyTwo>
          }
          {
            subscriptions.length > 1 && (
              <TextBodyTwo>
                <WarningIcon sx={{ display: 'inline', transform: 'translateY(4px)' }} />
                &nbsp; Notamos que tenés más de una subscripción activa. Te recomendamos quedarte con
                sólo una de ellas y cancelar la(s) otra(s). Escribinos a contact@smiledatepicker.com
              </TextBodyTwo>
            )
          }
        </>
      ) : (
        <>
          {/* {
            userFreeTrial?.status && (
              <TextBodyTwo color="green">
                Estás dentro del período de prueba gratuita, que terminará el &nbsp;
                <strong>{getLocaleFormattedDate(userFreeTrial.expires, getLocaleFromStoreData(storeData))}</strong>
              </TextBodyTwo>
            )
          } */}
          {
            scheduledDeliveriesMonthCount < 40 &&
            <TextBodyTwo color="green">
              En {getCurrentMonthName()} llevas {scheduledDeliveriesMonthCount} de 40 reservas mensuales gratuitas
            </TextBodyTwo>
          }
          {
            storeData?.main_currency === 'ARS' && (
              <>
                <Button onClick={onSubscribeMobbex} className="subscribe-btn" variant='contained'>
                  Subscribirse con Mobbex &nbsp;<img src="./mobbex_logo.png" className='payment-provider-logo' alt="Mobbex" />
                </Button>
                <Typography variant="body1" color="primary" sx={{textAlign: 'center', my: '16px', fontWeight: 600,  fontSize: '24px'}}>
                  Importante!
                </Typography>
                <Typography variant="body1" color="primary" sx={{textAlign: 'center'}}>
                  En "Referencia" o "Código de cliente" poner:
                </Typography>
                <Typography component="span" variant="body1" sx={{fontWeight: 600, fontSize: '24px', my: '16px'}} color="primary">
                  {(authData as any).user_id ?? 'smile_sub'}
                </Typography>
                <Divider className="subscriptions-divider" />
              </>
            )
          }
          {
            storeData?.main_currency !== 'ARS' && (
              <>
                <Button onClick={onSubscribe} className="subscribe-btn" variant='contained' disabled={loading}>
                  Subscribirse con Paypal &nbsp;<img src="./paypal_logo.png" className='payment-provider-logo' alt="Paypal" />
                </Button>
                <TextBodyTwo>
                  {initPointLink && (
                    <span>
                      Si no se abrió la pestaña de Paypal, podes usar{' '}
                      <a href={initPointLink}>éste link</a>
                    </span>
                  )}
                </TextBodyTwo>
                {!!error && <Typography variant="body1" color="red">{error}</Typography>}
              </>
            )
          }
        </>
      )}
      <div className="price">Pesos Argentinos: $8990 | Otros países: USD 10 (+ tax)</div>
    </Box>
  );
}

export default Subscription;
