import React, { useEffect, useState } from 'react';
import * as api from '../api';
import './Orders.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { SnackbarState } from '../components/Snackbar';
import { Order, StoreData } from '../shared/types';
import { useNavigate } from 'react-router-dom';
import { getCurrentMonthName, getLocaleFormattedDate, getLocaleFormattedTime, getLocaleFromStoreData } from '../shared/utils';
import { useGetDeliveriesForCurrentMonthCount } from '../shared/use-get-deliveries-for-month';

export interface OrdersProps {
  setSnackbar: (s: SnackbarState) => void;
  storeData: StoreData | undefined;
}

export function Orders({ setSnackbar, storeData }: OrdersProps) {
  const [loading, setLoading] = useState(false);
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [orders, setOrders] = useState<Order[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const scheduledDeliveriesMonthCount = useGetDeliveriesForCurrentMonthCount();
  const navigate = useNavigate();

  const getOrders = (currentOrders: Order[], currentPage: number, orderNumber: string) => {
    setLoading(true);
    api
      .getOrders(currentPage, orderNumber || '')
      .then((ordersData) => {
        if (ordersData && !ordersData.length) {
          setSnackbar({
            message: 'No hay más órdenes para cargar',
            open: true,
            severity: 'info'
          });
        }
        setOrders([...currentOrders, ...ordersData]);
      })
      .catch((err) => {
        if (err.statusCode === 401) {
          setSnackbar({
            message: 'Credenciales inválidas',
            open: true,
            severity: 'error'
          });
          navigate('/');
        }
        if (err.statusCode === 402) {
          setSnackbar({
            message: 'Debe estar subscripto para ver las órdenes',
            open: true,
            severity: 'error'
          });
          navigate('/subscription');
        }
      })
      .finally(() => setLoading(false));
  };

  // eslint-disable-next-line
  useEffect(() => getOrders(orders, 1, ''), []);

  const onSearchByOrderNumber = () => {
    setCurrentPage(1);
    setOrders([]);
    getOrders([], 1, orderNumber);
  };

  const onGetMoreOrders = () => {
    setCurrentPage(currentPage + 1);
    setOrderNumber('');
    getOrders(orders, currentPage + 1, '');
  };

  const onGetAllOrders = () => {
    setCurrentPage(1);
    setOrders([]);
    setOrderNumber('');
    getOrders([], 1, '');
  };

  return (
    <>
      <Typography variant="h4" component="h1">
        Órdenes
      </Typography>
      <div className="order-number-search">
        <TextField
          label="Número de órden"
          type="number"
          value={orderNumber}
          onChange={(e) => setOrderNumber(e.target.value)}
          size="small"
          sx={{backgroundColor: 'white', '&:hover': {backgroundColor: 'white'}}}
        />
        <Button
          color="primary"
          variant="outlined"
          onClick={onSearchByOrderNumber}
          disabled={loading}
          sx={{backgroundColor: 'white', '&:hover': {backgroundColor: 'white'}}}
        >
          Buscar
        </Button>
        {orderNumber && (
          <IconButton onClick={onGetAllOrders} aria-label="clear" color="warning">
            <ClearIcon />
          </IconButton>
        )}
        <span className="table-info">
          Los horarios indicados corresponden a {Intl.DateTimeFormat().resolvedOptions().timeZone}
        </span>
        <span className="table-info">
          Total de órdenes con reserva para {getCurrentMonthName()}: {scheduledDeliveriesMonthCount}
        </span>
      </div>
      {loading ? (
        <p>Cargando datos...</p>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="orders table">
            <TableHead>
              <TableRow>
                <TableCell>Orden</TableCell>
                <TableCell>Entrega</TableCell>
                <TableCell>Costo ({storeData?.main_currency ?? '$'})</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Inicio franja horaria</TableCell>
                <TableCell>Fin franja horaria</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((row: Order, index: number) => (
                <TableRow key={`${row.id}-${index}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    #{row.number}
                  </TableCell>
                  <TableCell>
                    {row.scheduled_delivery?.location ? row.scheduled_delivery.location.label : '-'}
                  </TableCell>
                  <TableCell>
                    {row.scheduled_delivery?.location
                      ? `$${row.scheduled_delivery.location.cost}`
                      : '-'}
                  </TableCell>
                  <TableCell>
                    {row.scheduled_delivery
                      ? getLocaleFormattedDate(row.scheduled_delivery.start, getLocaleFromStoreData(storeData))
                      : '-'}
                  </TableCell>
                  <TableCell>
                    {row.scheduled_delivery
                      ? getLocaleFormattedTime(row.scheduled_delivery.start, getLocaleFromStoreData(storeData))
                      : '-'}
                  </TableCell>
                  <TableCell>
                    {row.scheduled_delivery
                      ? getLocaleFormattedTime(row.scheduled_delivery.end, getLocaleFromStoreData(storeData))
                      : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div className="table-actions">
        <Button color="primary" onClick={onGetMoreOrders} variant="outlined"
          disabled={loading} sx={{backgroundColor: 'white', '&:hover': {backgroundColor: 'white'}}}>
          Cargar más órdenes
        </Button>
      </div>
    </>
  );
}

export default Orders;
