import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './TopNav.css';
import { Link } from 'react-router-dom';
import { clearAuthDataFromStorage, browserNavigateTo, getStoreNameForTopNav } from '../shared/utils';
import { StoreData } from '../shared/types';

const navItems = [
  { id: 1, text: 'Calendarios', route: '/' },
  { id: 2, text: 'Órdenes', route: '/orders' },
  { id: 3, text: 'Contacto', route: '/contact' },
  { id: 4, text: 'Ayuda', route: '/help' },
  { id: 5, text: 'Subscripción', route: '/subscription' }
];

export interface TopNavProps {
  storeData: StoreData | undefined;
}

export default function TopNav({ storeData }: TopNavProps) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const onLogout = () => {
    clearAuthDataFromStorage();
    browserNavigateTo(window.origin);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav" position="static">
        <Toolbar>
          {storeData && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography variant="h6" component="div" sx={{ display: { xs: 'none', md: 'block' } }}>
            <Link to={'/'}>
              <img src="./Datepicker_white.svg" className="logo" alt="logo" />
            </Link>
          </Typography>
          <Typography
            variant="h6"
            component="div"
            textAlign={storeData ? 'center' : 'left'}
            sx={{ flexGrow: 1, margin: '16px' }}
          >
            {getStoreNameForTopNav(storeData?.name?.es)}
          </Typography>
          {storeData && (
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              {navItems.map((item) => (
                <Button key={item.id} sx={{ color: '#fff' }}>
                  <Link to={item.route} className="link">
                    {item.text}
                  </Link>
                </Button>
              ))}
              <IconButton onClick={onLogout} aria-label="logout" sx={{ color: 'white' }}>
                <LogoutIcon />
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: 240,
              background: '#000000'
            }
          }}
        >
          <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} bgcolor="#000000">
            <div className="drawer-logo-container">
              <img src="./Datepicker.svg" className="logo logo-drawer" alt="logo" />
            </div>
            <Divider />
            <List>
              {storeData &&
                navItems.map((item) => (
                  <ListItem key={item.id} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }}>
                      <ListItemText>
                        <Link to={item.route} className="link">
                          {item.text}
                        </Link>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              <ListItem key="logout-btn" disablePadding>
                <IconButton onClick={onLogout} aria-label="logout">
                  <LogoutIcon />
                </IconButton>
              </ListItem>
            </List>
            <Divider />
            <IconButton onClick={onLogout} aria-label="logout" sx={{ color: 'white' }}>
              <LogoutIcon />
            </IconButton>
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
}
