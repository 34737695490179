import { v4 as uuidv4 } from 'uuid';
import { AuthData, Product, StoreData } from './types';

const LOCAL_STORAGE_AUTH_DATA_KEY = 'distel-tech-auth-data';

export function getAuthDataFromStorage(): AuthData {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_AUTH_DATA_KEY) || '{}');
}

export function setAuthDataToStorage(authData: AuthData): void {
  localStorage.setItem(LOCAL_STORAGE_AUTH_DATA_KEY, JSON.stringify(authData || '{}'));
}

export function clearAuthDataFromStorage(): void {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_DATA_KEY);
}

export function browserNavigateTo(url: string): void {
  window.location.replace(url);
}

export function createUniqueId(): string {
  return uuidv4();
}

export function getStoreNameForTopNav(name: string | undefined) {
  if (!name) {
    return 'Smile Datepicker';
  }
  if (name.length > 36) {
    return `${name.slice(0, 18)}...`;
  }
  return name;
}

export function getLocaleFormattedDate(date: Date | number | string, locale: string) {
  if (typeof date === 'number') {
    date = new Date(date);
  }
  return new Date(date).toLocaleDateString(locale ?? 'es-AR');
}

export function getLocaleFormattedTime(date: Date, locale: string) {
  return new Date(date).toLocaleTimeString(locale ?? 'es-AR', {
    hour: '2-digit',
    minute: '2-digit'
  });
}

export function getLocaleFromStoreData(storeData: StoreData | undefined) {
  if (!storeData) {
    return 'es-AR';
  }
  const language = storeData.main_language;
  const country = storeData.country;
  return `${language ?? 'es'}-${country ?? 'AR'}`;
}

export function getCurrentMonthName() {
  const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  const d = new Date();
  return monthNames[d.getMonth()];
}

export function getSelectedProductsText(products: Product[] | undefined) {
  if (!products?.length) {
    return 'No hay productos seleccionados. El calendario se aplicará para todos.';
  }
  const total = products.length;
  const s = total === 1 ? '' : 's';
  return `${total} producto${s} seleccionado${s}`;
}

export function withLeadingZero(num: number): string {
  if (num < 10) {
    return `0${num}`;
  }
  return `${num}`;
}