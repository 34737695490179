import React from 'react';

export function Contact() {
  return (
    <p>
      Por dudas, consultas o reclamos puede contactarse al siguiente email:{' '}
      <strong>contact@smiledatepicker.com</strong>
    </p>
  );
}

export default Contact;
