import React, { useEffect, useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  Switch,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Divider,
  MenuItem,
} from '@mui/material';
import { Form, Formik, FormikProps, getIn } from 'formik';
import * as Yup from 'yup';
import { createSettings, deleteSettings, getSettings, saveSettings, searchTiendanubeApiProducts } from '../api';
import './SettingsForm.css';
import { SnackbarState } from '../components/Snackbar';
import { Settings, Location, Product } from '../shared/types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BlockedDaysFieldsArray from '../components/BlockedDaysFieldsArray';
import BlockedTimeSlotsFieldsArray from '../components/BlockedTimeSlotsFieldsArray';
import LocationHelpDialog from '../components/LocationHelpDialog';
import { ConfirmDialogProps } from '../components/ConfirmDialog';
import ItemsSelectorDialog from '../components/ItemsSelectorDialog';
import { getSelectedProductsText } from '../shared/utils';
import DurationField from '../components/DurationField';

const validationSchema = Yup.object().shape({
  published: Yup.boolean(),
  allowCheckoutIfNoAvailability: Yup.boolean(),
  noAvailabilityMessage: Yup.string()
    .required('Completar campo')
    .min(1)
    .max(255, 'Máximo 255 caracteres'),
  minDays: Yup.number().min(0, '0 o más'),
  maxDays: Yup.number()
    .min(0, '0 o más')
    .max(1000, '1000 o menos')
    .test(
      'max-days-bigger-min-days',
      'Debe ser superior al mínimo',
      (value, ctx) => !!value && value > ctx.parent.minDays
    ),
  dayZeroLastCallTime: Yup.string(),
  minTime: Yup.string(),
  maxTime: Yup.string(),
  primaryColor: Yup.string(),
  slotDuration: Yup.string().test('min-ten-minutes-slots', 'Mínimo 10 minutos', (value) => {
    const [hours, minutes] = value?.split(':') || [];
    return Number(hours) > 0 || Number(minutes) >= 10;
  }),
  maxPerSlot: Yup.number().min(1, 'Mínimo de 1 envío por franja'),
  locationSelectorLabel: Yup.string()
    .max(255, 'Máximo 255 caracteres'),
  weekdays: Yup.object().shape({
    monday: Yup.boolean(),
    tuesday: Yup.boolean(),
    wednesday: Yup.boolean(),
    thursday: Yup.boolean(),
    friday: Yup.boolean(),
    saturday: Yup.boolean(),
    sunday: Yup.boolean()
  }),
  location: Yup.object().shape({
    id: Yup.string(),
    label: Yup.string(),
    cost: Yup.number(),
    selected: Yup.boolean(),
    deleted: Yup.boolean()
  }),
  blockedDays: Yup.array().of(Yup.string()),
  blockedTimeSlots: Yup.array()
});

const defaultInitialValues = {
  published: true,
  allowCheckoutIfNoAvailability: false,
  noAvailabilityMessage: 'No hay fecha/horario disponible. Prueba más tarde.',
  minDays: 1,
  maxDays: 9,
  dayZeroLastCallTime: undefined,
  minTime: '08:00',
  maxTime: '20:00',
  slotDuration: '01:00',
  primaryColor: '#000000',
  maxPerSlot: 4,
  locationSelectorLabel: 'Elija una franja horaria',
  weekDays: {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
    sunday: false
  },
  location: {
    id: '0',
    label: '-'
  },
  blockedDays: [],
  blockedTimeSlots: [],
  borderRadius: '8',
  storeTimezoneOffset: String(new Date().getTimezoneOffset()),
  onlyForProducts: []
};

export interface SettingsFormProps {
  setSnackbar: (s: SnackbarState) => void;
  locationOptions: Location[];
  storeData: any;
  setConfirmDialog: (data: ConfirmDialogProps) => void
}

export function SettingsForm({
  setSnackbar,
  locationOptions,
  storeData,
  setConfirmDialog
}: SettingsFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState<Settings>({} as Settings);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [locationHelpDialog, setLocationHelpDialog] = useState(false);
  const [isProductsDialogOpen, setProductsDialogOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<Product[] | undefined>();

  const initialValues = { ...defaultInitialValues, ...settings };

  useEffect(() => {
    setLoading(true);
    const settingsId = searchParams.get('id');
    if (!settingsId) {
      setLoading(false);
      return;
    }
    getSettings(settingsId)
      .then((data) => {
        setSettings(data);
        if (data.onlyForProducts) {
          setSelectedProducts([...data.onlyForProducts]);
        }
      })
      .finally(() => setLoading(false))
  }, [searchParams]);

  const processCalendarDeletion = () => {
    const id = searchParams.get('id');
    if (!id) {
      throw new Error('No id in search params');
    }
    deleteSettings(id)
      .then(() => {
        setSnackbar({
          message: 'Calendario eliminado',
          open: true,
          severity: 'success'
        });
        navigate('/');
      })
      .catch((e) => {
        console.error(e);
        setSnackbar({
          message: `Hubo un error al intentar eliminar el calendario - ${e.message}`,
          open: true,
          severity: 'error'
        });
      })
  };

  const onDelete = () => {
    setConfirmDialog({
      open: true,
      title: 'Eliminar calendario',
      content: '¿Está seguro de querer eliminar el calendario de forma permanente?',
      onClose: (confirmed: boolean) => {
        setConfirmDialog({open: false});
        confirmed && processCalendarDeletion();
      }
    });
  }

  const onSubmit = (values: Settings) => {
    setIsLoading(true);
    const payload: Settings = {
      ...values,
      location: locationOptions.find((l) => l.id === values.location.id) ?? settings.location,
      onlyForProducts: selectedProducts ?? settings.onlyForProducts
    };
    if (settings._id) {
      saveSettings(payload)
      .then(() => {
        setSnackbar({
          message: 'Configuración guardada',
          open: true,
          severity: 'success'
        });
        navigate('/');
      })
      .catch((e) => {
        console.error(e);
        setSnackbar({
          message: `Error: ${e.message}`,
          open: true,
          severity: 'error'
        });
      })
      .finally(() => setIsLoading(false));
      return;
    }
    createSettings(payload)
      .then(() => {
        setSnackbar({
          message: 'Nuevo calendario creado con éxito',
          open: true,
          severity: 'success'
        });
        navigate('/');
      })
      .catch((e) => {
        console.error(e);
        setSnackbar({
          message: `Error: ${e.message}`,
          open: true,
          severity: 'error'
        });
      })
      .finally(() => setIsLoading(false));
  };

  const onProductDialogClosed = (result: Product[] | undefined) => {
    if (result) {
      setSelectedProducts(result)
    }
    setProductsDialogOpen(false);
  };

  return (
    <>
      <Typography variant="h4" component="h1">
        Configuración
      </Typography>
      {
        !loading &&
        <div className="page-content">
          <section className="form-section">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                isValid,
                handleReset,
                setFieldValue,
              }: FormikProps<Settings>) => (
                <Form noValidate autoComplete="off" className="settings-form">
                  <FormControlLabel
                    className="span-all-columns"
                    control={
                      <Switch name="published" checked={values.published} onChange={handleChange} />
                    }
                    label={
                      values.published ? (
                        <span style={{ color: 'green' }}>
                          Calendario publicado (aparecerá en la tienda)
                        </span>
                      ) : (
                        <span style={{ color: 'red' }}>
                          Calendario pausado (no aparecerá en la tienda)
                        </span>
                      )
                    }
                  />
                  <Divider className="span-all-columns divider" />
                  <TextField
                    fullWidth
                    className="span-all-columns"
                    value={values.storeTimezoneOffset}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    select
                    label="Zona horaria"
                    name="storeTimezoneOffset"
                    size="small"
                    helperText="El comprador deberá estar dentro de la zona horaria indicada"
                    error={!['180', '240', '480', '420', '360', '300'].includes(values.storeTimezoneOffset)}
                  >
                    <MenuItem key={0} value={'180'}>Argentina o Chile (GMT-3)</MenuItem>
                    <MenuItem key={1} value={'240'}>Chile (GMT-4)</MenuItem>
                    <MenuItem key={2} value={'300'}>Mexico Quintana Roo (GMT-5)</MenuItem>
                    <MenuItem key={3} value={'300'}>Mexico Centro DST (GMT-5)</MenuItem>
                    <MenuItem key={4} value={'360'}>Mexico Centro (GMT-6)</MenuItem>
                    <MenuItem key={5} value={'360'}>Mexico Montaña/NO DST (GMT-6)</MenuItem>
                    <MenuItem key={6} value={'420'}>Mexico Montaña/NO (GMT-7)</MenuItem>
                    <MenuItem key={7} value={'420'}>Mexico Pacífico DST (GMT-7)</MenuItem>
                    <MenuItem key={8} value={'480'}>Mexico Pacífico (GMT-8)</MenuItem>
                  </TextField>
                  <Divider className="span-all-columns divider" />
                  <TextField
                    fullWidth
                    className="span-all-columns"
                    value={values.location.id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    select
                    label="Metodo de entrega vinculado"
                    name="location.id"
                    size="small"
                    error={values.location.id === '0'}
                  >
                    <MenuItem key={0} value={'0'}>{'- Ninguno -'}</MenuItem>
                    {
                      locationOptions.map(
                        (l) => <MenuItem key={l.id} value={l.id}>
                          {l.label} - ${storeData.main_currency} {l.cost}
                        </MenuItem>
                      )
                    }
                  </TextField>
                  <span className="span-all-columns text-button" onClick={() => setLocationHelpDialog(true)}>
                    ¿Por qué no aparece mi método de entrega?
                  </span>
                  <LocationHelpDialog open={locationHelpDialog} onClose={() => setLocationHelpDialog(false)} />
                  <TextField
                    id="location-selector-label"
                    name="locationSelectorLabel"
                    value={values.locationSelectorLabel}
                    className="span-all-columns"
                    fullWidth
                    onChange={handleChange}
                    label="Texto extra de ayuda"
                    size="small"
                  />
                  <Divider className="span-all-columns divider" />
                  <FormLabel component="legend" className='span-all-columns'>Días y horarios habilitados para entrega: </FormLabel>
                  <TextField
                    id="min-days"
                    name="minDays"
                    label="Mínimo de días"
                    type="number"
                    value={values.minDays}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    helperText={
                      getIn(touched, 'minDays') && getIn(errors, 'minDays')
                        ? getIn(errors, 'minDays')
                        : ''
                    }
                    error={Boolean(getIn(touched, 'minDays') && getIn(errors, 'minDays'))}
                  />
                  <TextField
                    id="max-days"
                    name="maxDays"
                    label="Máximo de días"
                    type="number"
                    value={values.maxDays}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    helperText={
                      getIn(touched, 'maxDays') && getIn(errors, 'maxDays')
                        ? getIn(errors, 'maxDays')
                        : ''
                    }
                    error={Boolean(getIn(touched, 'maxDays') && getIn(errors, 'maxDays'))}
                  />
                  {values.maxDays - values.minDays < 7 && (
                    <p className="brakets-error">
                      ¡Atención! Configuración restrictiva. Al haber pocos días de diferencia entre el mínimo y el máximo, puede
                      que un comprador se quede sin fechas posibles, dependiendo de los días de la
                      semana habilitados y el día específico en el que realice la compra.
                    </p>
                  )}
                  <TextField
                    id="time-start"
                    name="minTime"
                    label="Inicio horario"
                    type="time"
                    value={values.minTime}
                    onChange={handleChange}
                    size="small"
                  />
                  <TextField
                    id="time-end"
                    name="maxTime"
                    label="Fin de horario"
                    type="time"
                    value={values.maxTime}
                    onChange={handleChange}
                    size="small"
                  />
                  <FormControl component="fieldset" variant="standard" className="span-all-columns">
                    <FormGroup className="days-group">
                      <FormControlLabel
                        control={
                          <Switch
                            name="weekDays.monday"
                            checked={values.weekDays.monday}
                            onChange={handleChange}
                          />
                        }
                        label="Lunes"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            name="weekDays.tuesday"
                            checked={values.weekDays.tuesday}
                            onChange={handleChange}
                          />
                        }
                        label="Martes"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            name="weekDays.wednesday"
                            checked={values.weekDays.wednesday}
                            onChange={handleChange}
                          />
                        }
                        label="Miércoles"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            name="weekDays.thursday"
                            checked={values.weekDays.thursday}
                            onChange={handleChange}
                          />
                        }
                        label="Jueves"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            name="weekDays.friday"
                            checked={values.weekDays.friday}
                            onChange={handleChange}
                          />
                        }
                        label="Viernes"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            name="weekDays.saturday"
                            checked={values.weekDays.saturday}
                            onChange={handleChange}
                          />
                        }
                        label="Sábado"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            name="weekDays.sunday"
                            checked={values.weekDays.sunday}
                            onChange={handleChange}
                          />
                        }
                        label="Domingo"
                      />
                    </FormGroup>
                  </FormControl>
                  <Divider className="span-all-columns divider" />
                  <FormLabel component="legend" className='span-all-columns'>Duración de franja horaria</FormLabel>
                  <DurationField key={values.slotDuration} value={values.slotDuration} setFieldValue={setFieldValue} fieldName='slotDuration' />
                  <TextField
                    id="max-per-slot"
                    name="maxPerSlot"
                    label="Máximo de reservas por franja"
                    type="number"
                    value={values.maxPerSlot}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    helperText={
                      getIn(touched, 'maxPerSlot') && getIn(errors, 'maxPerSlot')
                        ? getIn(errors, 'maxPerSlot')
                        : ''
                    }
                    error={Boolean(getIn(touched, 'maxPerSlot') && getIn(errors, 'maxPerSlot'))}
                  />
                  <Divider className="span-all-columns divider" />
                  <FormLabel component="legend" className='span-all-columns'>Horario de corte</FormLabel>
                  {
                    values.minDays === 0 && <>
                      <TextField
                        id="day-zero-last-call-time"
                        name="dayZeroLastCallTime"
                        label="Hora límite para reservas en el día"
                        type="time"
                        value={values.dayZeroLastCallTime}
                        onChange={handleChange}
                        size="small"
                      />
                      <div style={{fontSize: '.8rem', opacity: '.6'}}>
                        Opcional. Significa que, pasada la hora límite, la disponibilidad más pronta
                        será para el siguiente día habilitado.
                      </div>
                    </>
                  }
                  <Divider className="span-all-columns divider" />
                    <BlockedTimeSlotsFieldsArray values={values} handleBlur={handleBlur}
                      handleChange={handleChange} />
                  <Divider className="span-all-columns divider" />
                    <BlockedDaysFieldsArray values={values} handleBlur={handleBlur}
                      handleChange={handleChange} errors={errors} />

                  <Divider className="span-all-columns divider" />
                  <FormLabel component="legend" className="span-all-columns divider">
                    Aplicar calendario sólo para determinados productos
                  </FormLabel>
                  <Typography sx={{color: '#1976d2'}}>
                    {getSelectedProductsText(selectedProducts)}
                  </Typography>
                  <Button
                      color="primary"
                      variant="outlined"
                      type="button"
                      onClick={() => setProductsDialogOpen(true)}
                      disabled={isLoading}
                      sx={{alignSelf: 'center'}}
                    >
                      Gestionar productos
                    </Button>
                  {
                    isProductsDialogOpen && <ItemsSelectorDialog<Product>
                      title='Seleccionar productos'
                      text='El calendario sólo aplicará para los productos seleccionados'
                      labelAccesorFn={(p: Product) => p.name}
                      avatarAccesorFn={(p: Product) => p.imageSrc}
                      open={true}
                      currentItems={selectedProducts}
                      onClose={onProductDialogClosed}
                      searchFn={searchTiendanubeApiProducts}
                    />
                  }

                  <Divider className="span-all-columns divider" />
                  <FormLabel component="legend" className="span-all-columns divider">
                    Opciones para casos extremos{' '}
                    <span style={{fontSize: '12px', display: 'block'}}>
                      (configuración de calendario muy restrictiva o compras fuera de la zona horaria de la tienda)
                    </span>
                  </FormLabel>
                  <TextField
                    id="no-availability-message"
                    name="noAvailabilityMessage"
                    value={values.noAvailabilityMessage}
                    className="span-all-columns"
                    fullWidth
                    onChange={handleChange}
                    label="Texto de no disponibilidad de fechas/horario"
                    size="small"
                  />
                  <FormControlLabel
                    className="span-all-columns"
                    control={
                      <Switch
                        name="allowCheckoutIfNoAvailability"
                        checked={values.allowCheckoutIfNoAvailability}
                        onChange={handleChange}
                      />
                    }
                    label="Permitir compra aún si no hay fechas/horarios posibles"
                  />
                  <Divider className="span-all-columns divider" />
                  <FormLabel component="legend" className="span-all-columns divider">
                    Diseño del calendario
                  </FormLabel>
                  <TextField
                      type="color"
                      value={values.primaryColor ?? '#000000'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Color primario (texto)"
                      name="primaryColor"
                      size="small"
                    />
                    <TextField
                      type="color"
                      value={values.secondaryColor ?? '#ffffff'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Color secundario (fondos)"
                      name="secondaryColor"
                      size="small"
                    />
                    <TextField
                      type="color"
                      value={values.notAvailableColor ?? '#D0D0D0'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Color de 'no disponible'"
                      name="notAvailableColor"
                      size="small"
                      helperText="para días u horarios"
                    />
                    <TextField
                      value={values.borderRadius ?? '8'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      select
                      label="Bordes"
                      name="borderRadius"
                      size="small"
                    >
                      <MenuItem key={0} value={'0'}>Rectos</MenuItem>
                      <MenuItem key={8} value={'8'}>Curvos</MenuItem>
                    </TextField>
                  <Divider className="span-all-columns divider" />
                  <div className="span-all-columns form-buttons">
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={!isValid || isLoading}
                    >
                      Guardar
                    </Button>
                    <Button
                      color="warning"
                      variant="outlined"
                      type="button"
                      onClick={onDelete}
                      disabled={isLoading}
                    >
                      Eliminar
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      type="button"
                      onClick={() => {
                        if (settings.onlyForProducts) {
                          setSelectedProducts([...settings.onlyForProducts]);
                        }
                        handleReset();
                      }}
                      disabled={isLoading}
                    >
                      Cancelar
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </section>
          <section className="preview-section"></section>
        </div>
      }
    </>
  );
}

export default SettingsForm;
