import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';

export interface ConfirmDialogProps {
  open: boolean;
  title?: string;
  content?: string;
  onClose?: (result: boolean) => void;
}

export function ConfirmDialog({ onClose, open, content, title }: ConfirmDialogProps) {
  return (
    <MuiDialog onClose={() => onClose && onClose(false)} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => onClose && onClose(true)}>OK</Button>
        <Button color="primary" variant="outlined" onClick={() => onClose && onClose(false)}>CANCELAR</Button>
      </DialogActions>
    </MuiDialog>
  );
}

export default ConfirmDialog;
