import React from 'react';
import CookieConsent from 'react-cookie-consent';

export function CookieBanner() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="acepto"
      style={{ padding: '8px' }}
      buttonStyle={{
        backgroundColor: 'white',
        color: '#1976D2',
        textTransform: 'uppercase',
        borderRadius: '4px',
        padding: '12px',
        fontWeight: 'bold'
      }}
    >
      Éste sitio utiliza cookies para mejorar la experiencia del usuario. Al permanecer en el sitio
      y utilizar la aplicación, usted da su consentimiento al utilizo de las cookies.
    </CookieConsent>
  );
}

export default CookieBanner;
