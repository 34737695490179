import React from 'react';
import { Typography } from '@mui/material';

export function TextBodyTwo({ children, color = '#1976d2' }: any) {
  return (
    <Typography
      variant="body2"
      component="div"
      sx={{ color, margin: '32px', justifyContent: 'block', textAlign: 'center' }}
    >
      {children}
    </Typography>
  );
}

export default TextBodyTwo;
