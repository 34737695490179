import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Box,
  Paper,
  IconButton
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

interface IdEntity {
  id: number | string;
}

export interface ItemsSelectorDialogProps<T extends IdEntity> {
  currentItems: T[] | undefined;
  searchFn: (searchTerm: string) => Promise<T[]>;
  open: boolean;
  onClose: (result?: T[]) => void;
  title?: string;
  text?: string;
  labelAccesorFn: (item: T) => string;
  avatarAccesorFn: (item: T) => string;
}

export function ItemsSelectorDialog<T extends IdEntity>({
  onClose,
  open,
  text,
  title,
  currentItems,
  searchFn,
  labelAccesorFn,
  avatarAccesorFn
}: ItemsSelectorDialogProps<T>)
{
  const [searchTerm, setSearchTerm] = React.useState('');
  const [foundItems, setFoundItems] = React.useState<T[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState([...(currentItems ?? [])]);

  const onSearch = () => {
    setLoading(true);
    searchFn(searchTerm)
      .then((res) => setFoundItems(res))
      .finally(() => setLoading(false));
  };

  const avatar = (src: string) =>  <img src={src ?? 'product_placeholder.png'} alt=''
      style={{height: '28px', width: '28px', borderRadius: '50%', border: '1px solid #1976D2', objectFit: 'cover'}}>
    </img>;

  return (
    <MuiDialog onClose={() => onClose && onClose()} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
        <Box sx={{display: 'flex', gap: '24px', alignItems: 'flex-start', marginTop: '16px'}}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Box sx={{display: 'flex', gap: '4px', margin: '4px'}}>
              <TextField
                id="items-search"
                value={searchTerm}
                label="Buscar items"
                size="small"
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    onSearch();
                  }
                }}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button color="primary" variant="outlined" onClick={() => onSearch()} disabled={loading}>
                BUSCAR
              </Button>
            </Box>
            
            {foundItems.map((item: T) => <Paper
                key={item.id}
                elevation={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  padding: '0 0 0 8px',
                  overflowX: 'hidden',
                  margin: '4px 0',
                  border: '1px solid #1976D2',
                  color: '#1976D2'
                }}
              >
                {avatar(avatarAccesorFn(item))}
                {labelAccesorFn(item)}
                <IconButton
                    sx={{marginLeft: 'auto'}}
                    onClick={() => setSelectedItems([...selectedItems, item])}
                    aria-label="add"
                    color="primary"
                    disabled={selectedItems.map(x => x.id).includes(item.id)}
                  >
                    <AddIcon />
                </IconButton>
              </Paper>)}
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
            {selectedItems.map((item: T) => <Paper
              key={item.id}
              elevation={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                width: '276px',
                overflowX: 'hidden',
                margin: '4px 0',
                padding: '0 0 0 8px',
                color: 'white',
                backgroundColor: '#1976D2',
              }}
            >
              {avatar(avatarAccesorFn(item))}
              {labelAccesorFn(item)}
              <IconButton
                  sx={{marginLeft: 'auto'}}
                  onClick={() => setSelectedItems([...selectedItems.filter(x => x.id !== item.id)])}
                  aria-label="delete"
                  color="warning"
                >
                  <ClearIcon />
              </IconButton>
            </Paper>)}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => onClose && onClose(selectedItems)}>Guardar</Button>
        <Button color="primary" variant="outlined" onClick={() => onClose && onClose()}>CANCELAR</Button>
      </DialogActions>
    </MuiDialog>
  );
}

export default ItemsSelectorDialog;
