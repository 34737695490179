import React, { useEffect, useState } from 'react';
import Footer from './components/Footer';
import TopNav from './components/TopNav';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SettingsForm from './pages/SettingsForm';
import { authWithCode, authWithData } from './api';
import { getAuthDataFromStorage, setAuthDataToStorage } from './shared/utils';
import Orders from './pages/Orders';
import { Login } from './pages/Login';
import { Privacy } from './pages/Privacy';
import { TermsAndConditions } from './pages/TermsAndConditions';
import Help from './pages/Help';
import CookieBanner from './components/CookieBanner';
import Contact from './pages/Contact';
import { StoreData, UserFreeTrialData, Location, AuthData } from './shared/types';
import { Snackbar, SnackbarState } from './components/Snackbar';
import { ErrorBoundary } from 'react-error-boundary';
import { Subscription } from './pages/Subscription';
import { SettingsList } from './pages/SettingsList';
import ConfirmDialog, { ConfirmDialogProps } from './components/ConfirmDialog';

function App() {
  const [storeData, setStoreData] = useState<StoreData | undefined>();
  const [subscriptions, setSubscriptions] = useState<any | undefined>();
  const [locationOptions, setLocationOptions] = useState<Location[]>([]);
  const [userFreeTrialPeriod, setUserFreeTrialPeriod] = useState<UserFreeTrialData>();
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    severity: 'success',
    message: ''
  });
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogProps>({open: false});
  const [authData, setAuthData] = useState<AuthData | {}>({});

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (code) {
      authWithCode(code)
        .then((res) => {
          setAuthDataToStorage(res.authData);
          setStoreData(res.store);
          setSubscriptions(res.subscriptions);
          setUserFreeTrialPeriod(res.userFreeTrialPeriod);
          setLocationOptions(res.locationOptions);
          setAuthData(res.authData);
        })
        .finally(() => {
          window.history.replaceState({}, 'Smile Datepicker', window.origin);
          setLoading(false);
        });
    } else {
      const authDataFromStorage = getAuthDataFromStorage();
      authWithData(authDataFromStorage)
        .then((res) => {
          setStoreData(res.store);
          setSubscriptions(res.subscriptions);
          setUserFreeTrialPeriod(res.userFreeTrialPeriod);
          setLocationOptions(res.locationOptions);
          setAuthData(authDataFromStorage);
        })
        .catch((err) => {
          if (
            err.statusCode === 401
            && (['/orders', '/settings', '/subscription'].includes(window.location.pathname))
          ) {
            window.location.href = window.origin;
          }
        })
        .finally(() => setLoading(false));
    }
  }, []);

  return (
    <Router>
      <TopNav storeData={storeData} />
      <main className="main">
        <ErrorBoundary
          FallbackComponent={() => (
            <h3>
              Algo salió mal, probá reiniciando la app: <a href={window.origin}>reiniciar</a>
            </h3>
          )}
        >
          <Routes>
            <Route path="/orders" element={<Orders setSnackbar={setSnackbar} storeData={storeData} />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/help" element={<Help />} />
            <Route
              path="/subscription"
              element={
                <Subscription
                  authData={authData}
                  storeData={storeData}
                  subscriptions={subscriptions}
                  userFreeTrial={userFreeTrialPeriod}
                />
              }
            />
            <Route
              path="/settings"
              element={
                <SettingsForm
                  setConfirmDialog={setConfirmDialog}
                  setSnackbar={setSnackbar}
                  locationOptions={locationOptions}
                  storeData={storeData}
                />
              }
            />
            <Route
              path="/"
              element={
                loading ? (
                  <h2>Recuperando datos...</h2>
                ) : storeData ? (
                  <SettingsList setSnackbar={setSnackbar} />
                ) : (
                  <Login setLoading={setLoading} />
                )
              }
            />
          </Routes>
        </ErrorBoundary>
      </main>
      <Footer />
      <Snackbar snackbar={snackbar} setSnackbar={setSnackbar} />
      <ConfirmDialog {...confirmDialog} />
      <CookieBanner />
    </Router>
  );
}

export default App;
