import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

export function Footer() {
  return (
    <footer className="footer">
      <span>&#169; Smile Datepicker</span>
      <div>
        <span></span>
        <Link to="/privacy" className="link">
          Política de privacidad
        </Link>
        <Link to="/terms" className="link">
          Términos de uso y condiciones
        </Link>
      </div>
    </footer>
  );
}

export default Footer;
