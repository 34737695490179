import * as React from 'react';
import { Button, FormControl, FormGroup, FormLabel, IconButton, TextField } from '@mui/material';
import { FieldArray, FormikProps, getIn } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import './BlockedDaysFieldsArray.css';

export function BlockedDaysFieldsArray({values, handleChange, errors, handleBlur}: Partial<FormikProps<any>>) {
  return (
    <FieldArray name="blockedDays">
      {(arrayHelpers) => (
        <>
          <FormLabel component="legend" className="form-group-label">
            Bloquear días específicos
          </FormLabel>
          <FormGroup className="blocked-day-fields-array">
            {values.blockedDays.map((blockedDay: string, index: number) => {
                return (
                  <FormControl
                    key={index}
                    component="fieldset"
                    variant="standard"
                    className="blocked-day-item"
                  >
                    <TextField
                      name={`blockedDays.${index}`}
                      type="date"
                      value={blockedDay}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                      error={!blockedDay}
                      helperText={
                        getIn(errors, `blockedDays.${index}`)
                          ? getIn(errors, `blockedDays.${index}`)
                          : ''
                      }
                    />
                    <IconButton
                      onClick={() => arrayHelpers.remove(index)}
                      aria-label="delete"
                      color="warning"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </FormControl>
                );
              }
            )}
            <Button
              className="add-button"
              type="button"
              variant="text"
              onClick={() => arrayHelpers.push('')}
              disabled={values.blockedDays.length >= 50}
            >
              + Añadir día
            </Button>
          </FormGroup>
        </>
      )}
    </FieldArray>
  );
}

export default BlockedDaysFieldsArray;
