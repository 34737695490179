import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';

export interface LocationHelpDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
}

export function LocationHelpDialog({ onClose, open }: LocationHelpDialogProps) {
  return (
    <MuiDialog onClose={() => onClose(true)} open={open}>
      <DialogTitle>Añadir entrega personalizada</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Para saber qué son las entregas personalizadas de Tiendanube y cómo puedes configurarlas
          en el administrador de tu tienda, te recomendamos el siguiente link de la{' '}
          <a href="https://ayuda.tiendanube.com/es_ES/122892-envios-personalizados/como-ofrecer-otros-envios-o-entregas-personalizadas" target={'_blank'} rel='noreferrer'>
            ayuda
          </a>{' '}
          de Tiendanube.
        </DialogContentText>
        <br />
        <DialogContentText>
          <span style={{color: 'orangered'}}>¡Importante!</span> Una vez creada una entrega personalizada, debes generar al menos una órden
          para que aparezca disponible aquí en Smile Datepicker, para vincular con el calendario.
        </DialogContentText>
        <DialogContentText>
          De igual manera deberás proceder en caso de que edites una de las entregas personalizadas:
          1) generar orden;
          2) reiniciar app Smile Datepicker (refrescar página)
        </DialogContentText>
        <br />
        <DialogContentText>
          Aquí dejamos un breve{' '}<a href="https://youtu.be/bYmlBVfu3pA" target={'_blank'} rel='noreferrer'>video explicativo</a>{' '} acerca de todo ésto.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(true)}>cerrar</Button>
      </DialogActions>
    </MuiDialog>
  );
}

export default LocationHelpDialog;
