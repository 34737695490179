import React from 'react';

export function Help() {
  return (
    <section className="faq-body-section">
      <p className="faq-question">¿Qué es Smile Datepicker?</p>
      <p>
        Una aplicación que introduce un widget de calendario en la página del checkout. De ésta
        manera, el comprador puede elegir, dentro de las restricciones impuestas por el vendedor en
        la configuración, la fecha y el horario de la entrega del producto.
      </p>
      <p className="faq-question">¿Quién debería utilizar Smile Datepicker?</p>
      <p>
        Ésta aplicación le resulta útil a aquellas tiendas que precisen de una mejor coordinación,
        con el comprador, para las entregas de los productos/servicios que la tienda comercializa.
      </p>
      <p>
        Por el momento no serviría para tiendas que comercialicen productos o servicios digitales.
        A menos que sus compradores pertenezcan a la misma zona horaria que la tienda.
      </p>
      <p className="faq-question">¿Cómo funciona?</p>
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/2pS1IAqN2Og"
        title="Tutorial de Smile Datepicker"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <p className="faq-question">¿Cómo funciona el plan gratuito?</p>
      <p>
        Dentro del plan gratuito, tienes hasta 40 órdenes mensuales gratis con reserva mediante Smile Datepicker.
        Al principio de cada mes se resetea el conteo. Si precisas de más capacidad, deberás subscribirte.
      </p>
      <p>
        Una vez subscripto, ya no aplica el plan gratuito.
      </p>
      <p className="faq-question">¿Cómo puedo probar la aplicación?</p>
      <p>
        La mejor forma de probar la configuración es como se muestra en el &nbsp;
        <a href="https://www.youtube.com/watch?v=2pS1IAqN2Og" target={'_blank'} rel='noreferrer'>video tutorial</a>
      </p>
      <p>
        Es decir: tener abierto tu tienda con una página de checkout. Si uno actualiza la página
        luego de cambiar (y guardar) la configuración del calendario, podrá ver los cambios tal como los vería
        un usuario.
      </p>
      <p className="faq-question">
        ¿Por qué en lugar del calendario veo un mensaje que indica que no hay fechas disponibles?
      </p>
      <p>
        La causa más probable de ése mensaje es una configuración que deja al comprador sin fechas
        disponibles para elegir en el momento de la compra. Tenga en cuenta que usted determina
        reglas para la disponibilidad de fechas y horarios. Imagine que usted determina que el mínmo
        de días es 3 y el máximo es 5, sin embargo usted no hace envíos el fin de semana. Ésto
        resultaría en una situación en donde, un cliente que está comprando en su tienda un Viernes,
        no tendría fechas disponibles.
      </p>
      <p className="faq-question">
        ¿Qué sucede si elimino un método de entrega (entrega personalizada) y un comprador ya lo había elegido?
      </p>
      <p>
        En éste caso, la elección del comprador permanecerá con su elección original. Si usted
        elimina un método de entrega, verá que aquellas órdenes que lo habían elegido, aún
        reflejarán ésa elección. Consideramos que éste es el comportamiento deseado para la mayor
        parte de las tiendas, ya que se honra la elección original del consumidor.
      </p>
    </section>
  );
}

export default Help;
