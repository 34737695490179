import { AuthData, AuthResponse, Order, Settings, Product } from './shared/types';
import { getAuthDataFromStorage } from './shared/utils';

const API_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3001'
    : 'https://smiledatepicker.herokuapp.com';

function stringifyBody(body: any): string | undefined {
  if (!body) {
    return undefined;
  }
  if (typeof body === 'string') {
    return body;
  }
  return JSON.stringify(body);
}

async function callApi(path: string, method = 'GET', body?: any): Promise<any> {
  const authData = getAuthDataFromStorage();
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'distel-tech-store-id': String(authData.user_id),
    'distel-tech-store-token': authData.access_token
  };
  return fetch(path, { method, body: stringifyBody(body), headers })
    .then(async (res) => {
      if (res.status >= 400) {
        const err = await res.json();
        return Promise.reject(err);
      }
      return res;
    })
    .then((res) => res.json())
}

export function getAllSettings(): Promise<Settings[]> {
  return callApi(`${API_URL}/settings`);
}

export function deleteSettings(id: string): Promise<Settings> {
  return callApi(`${API_URL}/settings/${id}`, 'DELETE');
}

export function getSettings(id: string): Promise<Settings> {
  return callApi(`${API_URL}/settings/${id}`);
}

export function saveSettings(settings: Settings): Promise<Settings> {
  return callApi(`${API_URL}/settings`, 'PUT', settings);
}

export function createSettings(settings: Settings): Promise<Settings> {
  return callApi(`${API_URL}/settings`, 'POST', settings);
}

export function searchTiendanubeApiProducts(searchTerm: string): Promise<Product[]> {
  return callApi(`${API_URL}/settings/products?searchTerm=${searchTerm}`);
}

export function authWithCode(code: string): Promise<AuthResponse> {
  return callApi(`${API_URL}/authorize?code=${code}`);
}

export function authWithData(authData: AuthData): Promise<AuthResponse> {
  return callApi(`${API_URL}/authorize`, 'POST', authData);
}

export function getOrders(currentPage: number, orderNumber?: string): Promise<Order[]> {
  return callApi(`${API_URL}/orders?pageNumber=${currentPage}&orderNumber=${orderNumber || ''}`);
}

export function subscribe(): Promise<any> {
  return callApi(`${API_URL}/subscriptions/paypal`);
}

export function cancelPaypalSubscription(): Promise<any> {
  return callApi(`${API_URL}/subscriptions/paypal/cancel`, 'DELETE');
}

export function getScheduledDeliveriesMonthCount(): Promise<{count: number}> {
  return callApi(`${API_URL}/scheduled_deliveries/month_count`);
}
