import React, { useEffect, useState } from 'react';
import { Settings } from '../shared/types';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { getAllSettings } from '../api';
import { SnackbarState } from '../components/Snackbar';
import Calendar from '../components/Calendar';
import './SettingsList.css';

interface SettingsListProps {
  setSnackbar: (s: SnackbarState) => void;
}

export function SettingsList({ setSnackbar }: SettingsListProps) {
  const [allSettings, setAllSettings] = useState<Settings[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    getAllSettings()
      .then((data: Settings[]) => setAllSettings(data))
      .catch((err: any) => {
        console.error(err);
        setSnackbar({
          message: `Hubo un error al intentar obtener los calendarios - ${err.message}`,
          open: true,
          severity: 'error'
        });
      })
    // eslint-disable-next-line
  }, [])
  if (!allSettings) {
    return <p>Cargando datos...</p>
  }
  const actionsRow = <div>
    <Button
      color="primary"
      variant="contained"
      type="button"
      disabled={allSettings.length >= 20}
      sx={{ marginBottom: '16px' }}
      onClick={() => navigate('/settings')}
    >
      <AddIcon /> Crear calendario
    </Button>
  </div>
  if (!allSettings.length) {
    return actionsRow;
  }
  return (
    <>
      {actionsRow}
      <div className='calendars-container'>
        {
          allSettings.map(
            (row: Settings) =>
              <Calendar
                key={row._id}
                label={row.location.label}
                onClick={() => navigate(`/settings?id=${row._id}`)}
              />
            )
        }
      </div>
    </>
  )
}