import * as React from 'react';
import { Button, FormControl, FormGroup, FormLabel, IconButton, MenuItem, TextField } from '@mui/material';
import { FieldArray, FormikProps } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import './BlockedTimeSlotsFieldsArray.css';
import { BlockedTimeSlot } from '../shared/types';

export function BlockedTimeSlotsFieldsArray({ values, handleChange, handleBlur }: Partial<FormikProps<any>>) {
  return (
    <FieldArray name="blockedTimeSlots">
      {(arrayHelpers) => (
        <>
          <FormLabel component="legend" className="form-group-label">
            Bloquear horarios específicos
          </FormLabel>
          <FormGroup className="blocked-time-slot-fields-array">
            {values.blockedTimeSlots.map((blockedTimeSlot: BlockedTimeSlot, index: number) => {
              return (
                <FormControl
                  key={index}
                  component="fieldset"
                  variant="standard"
                  className="blocked-time-slot-item"
                >
                  <TextField
                    value={blockedTimeSlot.weekDay ?? 7}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="week-day-input"
                    fullWidth
                    select
                    label="Día de la semana"
                    name={`blockedTimeSlots.${index}.weekDay`}
                    size="small"
                    error={typeof blockedTimeSlot.weekDay !== 'number'}
                  >
                    {
                      ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'].map(
                        (weekDay: string, i: number) =>
                          <MenuItem key={weekDay} value={i}>{weekDay}</MenuItem>
                      )
                    }
                  </TextField>
                  <TextField
                    name={`blockedTimeSlots.${index}.start`}
                    label="Inicio"
                    type="time"
                    className="time-input"
                    fullWidth
                    value={blockedTimeSlot.start ?? '00:00'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    error={!blockedTimeSlot.start}
                  />
                  <TextField
                    name={`blockedTimeSlots.${index}.end`}
                    label="Fin"
                    type="time"
                    className="time-input"
                    fullWidth
                    value={blockedTimeSlot.end ?? '00:00'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    error={!blockedTimeSlot.end}
                  />
                  <IconButton
                    onClick={() => arrayHelpers.remove(index)}
                    aria-label="delete"
                    color="warning"
                  >
                    <DeleteIcon />
                  </IconButton>
                </FormControl>
              );
            }
            )}
            <Button
              className="add-button"
              type="button"
              variant="text"
              onClick={() => arrayHelpers.push('')}
              disabled={values.blockedTimeSlots.length >= 50}
            >
              + Añadir horario
            </Button>
          </FormGroup>
        </>
      )}
    </FieldArray>
  );
}

export default BlockedTimeSlotsFieldsArray;
