import React from 'react';

export function TermsAndConditions() {
  return (
    <>
      <h1>Términos de uso y condiciones</h1>
      <section className="terms-body-section">
        <p>Esta política de condiciones de uso es válida a partir del 14 de Octubre del 2022.</p>
        <p className="terms-subtitle">TÉRMINOS Y CONDICIONES — Smile Datepicker</p>
        <p>
          Smile Datepicker, persona jurídica de derecho privado describe, a través de este
          documento, las normas de uso del sitio https://www.smiledatepicker.com y de cualquier
          otro sitio web, tienda o aplicación operada por el titular.
        </p>
        <p>
          Al navegar por este sitio web y utilizar la aplicación, consideramos que está de acuerdo con las condiciones de uso
          que figuran a continuación.
        </p>
        <p>
          Si no está de acuerdo con los términos de este acuerdo, le pedimos que no haga ningún otro
          uso de este sitio web, mucho menos que se registre o envíe sus datos personales.
        </p>
        <p>
          Si cambiamos nuestras condiciones de uso, publicaremos el nuevo texto en este sitio web,
          con una fecha de revisión actualizada. Podemos modificar este documento en cualquier
          momento.
        </p>
        <p>
          Si hay un cambio significativo en los términos de este acuerdo, podemos informarle
          utilizando la información de contacto que tenemos en nuestra base de datos o mediante una
          notificación.
        </p>
        <p>
          El uso de este sitio web después de los cambios significa que usted acepta las condiciones
          de uso revisadas. Si, después de leer la versión revisada, no está de acuerdo con sus
          términos, por favor, termine su acceso.
        </p>
        <p className="terms-subtitle">Sección 1 - Usuario</p>
        <p>
          El uso de este sitio web le otorga automáticamente la condición de Usuario e implica su
          plena aceptación de todas las directrices y condiciones incluidas en estas Condiciones.
        </p>
        <p>
          Por otro lado, está también el cliente final de la Tiendanube del usuario.
          No recolectamos ningún tipo de dato del cliente, más allá de su elección de fecha y horario,
          para cumplir con los fines de la app Smile Datepicker.
        </p>
        <p>
          Para visualizar correctamente el calendario el cliente deberá contar con una versión con la actualización más reciente
          de navegadores web Google Chrome, Firefox o Safari utilizando una laptop, notebook, computadora de escritorio
          o teléfono celular smartphone. No soportamos Internet Explorer, el cual quedó obsoleto en Junio del 2022. Y tampoco
          el modo "compatibilidad" ofrecido por el navegador Edge.
        </p>
        <p>
          El calendario, sólo funciona correctamente en la misma zona horaria de la Tienda.
        </p>
        <p className="terms-subtitle">Sección 2 - Adhesión junto con la política de privacidad</p>
        <p>
          El uso de este sitio web implica la adhesión a estas Condiciones de Uso y a la versión más
          actualizada de la Política de Privacidad de Smile Datepicker.
        </p>
        <p className="terms-subtitle">Sección 3 - Condiciones de acceso</p>
        <p>
          El acceso al sitio web de Smile Datepicker no requiere registro previo, ya que se
          autentica por medio de Tiendanube.
        </p>
        <p>
          Es responsabilidad del usuario proporcionar únicamente información correcta, auténtica,
          válida, completa y actualizada, así como no revelar su nombre de usuario y contraseña a
          terceros.
        </p>
        <p className="terms-subtitle">Sección 4 - Cookies</p>
        <p>
          La información sobre el uso que usted hace de este sitio web puede recogerse a través de
          las cookies. Las cookies son piezas de información que se almacenan directamente en el
          equipo que está utilizando. Las cookies permiten recoger información como el tipo de
          navegador, el tiempo de permanencia en el sitio web, las páginas visitadas, las
          preferencias de idioma y otros datos de tráfico anónimos. Nosotros y nuestros proveedores
          de servicios utilizamos la información para la protección de la seguridad, para facilitar
          la navegación, mostrar la información de manera más eficiente y personalizar su
          experiencia al utilizar este sitio web, así como para el seguimiento en línea. También
          recopilamos información estadística sobre el uso del sitio web para mejorar continuamente
          nuestro diseño y funcionalidad, para entender cómo se utiliza el sitio web y para ayudarle
          a resolver problemas relevantes.
        </p>
        <p>
          Si no desea que se recoja su información a través de las cookies, existe un procedimiento
          sencillo en la mayoría de los navegadores que permite rechazar automáticamente las
          cookies, o le da la opción de aceptar o rechazar la transferencia de una cookie específica
          (o varias) de un sitio web concreto a su ordenador. Sin embargo, esto puede crear
          inconvenientes en su uso del sitio web.
        </p>
        <p>
          La configuración que elija puede afectar a su experiencia de navegación y al
          funcionamiento que requiere el uso de cookies. En este sentido, rechazamos cualquier
          responsabilidad por las consecuencias derivadas del funcionamiento limitado de este sitio
          web causado por la desactivación de las cookies en su dispositivo (incapacidad para
          establecer o leer una cookie).
        </p>
        <p className="terms-subtitle">Sección 5 - Propiedad intelectual</p>
        <p>
          Todos los elementos de Smile Datepicker son propiedad intelectual de la misma o de sus
          licenciantes. Estos términos o el uso del sitio web no le otorgan ninguna licencia o
          derecho de uso de los derechos de propiedad intelectual de Smile Datepicker o de cualquier
          tercero.
        </p>
        <p className="terms-subtitle">Sección 6 - Enlaces a sitios web de terceros</p>
        <p>
          Este sitio web puede contener, de vez en cuando, enlaces de hipertexto que le redirigirán
          a sitios web de nuestros socios, anunciantes, proveedores, etc. Si hace clic en uno de
          estos enlaces a cualquiera de estos sitios, recuerde que cada sitio tiene sus propias
          prácticas de privacidad y que nosotros no somos responsables de estas políticas. Por
          favor, consulte esas políticas antes de enviar cualquier dato personal a esos sitios.
        </p>
        <p>
          No somos responsables de las políticas y prácticas de recopilación, uso y divulgación
          (incluidas las prácticas de protección de datos) de otras organizaciones, como Facebook,
          Apple, Google, Microsoft, o cualquier otro desarrollador de software o proveedor de
          aplicaciones, tienda de medios sociales, sistema operativo, proveedor de servicios de
          internet o fabricante de dispositivos, incluidos los Datos Personales que usted divulgue a
          otras organizaciones a través de las aplicaciones, en relación con dichas aplicaciones, o
          publicados en nuestras redes sociales. Le recomendamos que se informe sobre la política de
          privacidad y las condiciones de uso de cada sitio web visitado o proveedor de servicios
          utilizado.
        </p>
        <p className="terms-subtitle">Sección 7 - Plazos y modificaciones</p>
        <p>El funcionamiento de este sitio web es por tiempo indefinido.</p>
        <p>
          La totalidad del sitio web o cada una de sus secciones podrá ser cerrada, suspendida o
          interrumpida unilateralmente por Smile Datepicker, en cualquier momento y sin previo
          aviso.
        </p>
        <p>
          Smile Datepicker se aboga el derecho a eliminar las elecciones de fecha/horario ya pasadas,
          dejando disponibles las de los últimos 30 días.
        </p>
        <p className="terms-subtitle">Sección 8 - Datos personales</p>
        <p>
          Durante el uso de este sitio web, ciertos datos personales serán recogidos y procesados
          por Smile Datepicker y/o los Socios. Las normas relativas al tratamiento de los datos
          personales de Smile Datepicker están estipuladas en la Política de Privacidad.
        </p>
        <p className="terms-subtitle">Sección 9 - Subscripción</p>
        <p>
          La subscripción se hace mediante Mercadopago. Smile Datepicker no almacena ninguna información
          de cobro (por ejemplo tarjetas de crédito).
          Si el usuario desea cancelar la subscripción, debe enviar un correo electrónico a contact@smiledatepicker.com
        </p>
        <p className="terms-subtitle">Sección 10 - Almacenamiento de datos</p>
        <p>
          Los datos generados se almacenan en una base de datos gestionada por Amazon Web Services (AWS).
        </p>
        <p className="terms-subtitle">Sección 11 - Contacto</p>
        <p>
          Si tiene alguna pregunta sobre las condiciones de uso, por favor póngase en contacto con
          nosotros por correo electrónico contact@smiledatepicker.com.
        </p>
      </section>
    </>
  );
}

export default TermsAndConditions;
