import React from 'react';
import { Paper } from '@mui/material';
import './Calendar.css';

export interface CalendarProps {
  label: string;
  onClick: (args?: any) => void;
}

export function Calendar({label, onClick}: CalendarProps) {
  return (
    <Paper
      onClick={onClick}
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '16px',
        alignItems: 'center',
        width: '100%',
        maxWidth: '120px',
        height: '120px',
        backgroundColor: 'white',
        border: '4px solid rgba(25,118,210,1)',
        borderRadius: '8px',
        padding: '16px',
        color: 'rgba(25,118,210,1)',
        fontWeight: '400',
        cursor: 'pointer',
        '&:hover': {
          transform: 'scale(1.02)',
          transition: 'all 250ms ease-in'
        }
      }}
    >
      <img src="./Datepicker_white.svg" className="calendar-image" alt="logo" />
      <div style={{textAlign: 'center'}}>
        {label.length > 30 ? `${label.slice(0,28)} ...` : label}
      </div>
    </Paper>
  );
}

export default Calendar;
