import React from 'react';
import { Snackbar as MuiSnackbar, Alert, AlertColor } from '@mui/material';

export interface SnackbarState {
  open: boolean;
  severity?: AlertColor;
  message?: string;
}

export interface SnackbarProps {
  snackbar: SnackbarState;
  setSnackbar: (s: SnackbarState) => void;
}

export function Snackbar({ snackbar, setSnackbar }: SnackbarProps) {
  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ open: false });
  };
  return (
    <MuiSnackbar
      open={snackbar.open}
      autoHideDuration={5000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert severity={snackbar.severity} onClose={handleCloseSnackbar}>
        {snackbar.message}
      </Alert>
    </MuiSnackbar>
  );
}
