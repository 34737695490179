import React from 'react';
import { Button, Box } from '@mui/material';
import './Login.css';

const DEV_APP_ID = 5710;
const PROD_APP_ID = 5433;
const TIENDANUBE_APP_ID = process.env.NODE_ENV === 'development' ? DEV_APP_ID : PROD_APP_ID;

export interface LoginProps {
  setLoading: (loading: boolean) => void;
}

export function Login({ setLoading }: LoginProps) {
  return (
    <Box
      sx={{
        width: 300,
        height: 300,
        backgroundColor: '#1976d2',
        margin: '0 auto',
        borderRadius: '16px',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 8,
        transform: 'translateY(20vh)',
        '&:hover': {
          backgroundColor: '#1976d2',
          opacity: [0.95, 0.9]
        }
      }}
    >
      <img src="./Datepicker_white.svg" className="login-logo" alt="logo" />
      <Button variant="contained" sx={{ background: 'white' }} onClick={() => setLoading(true)}>
        <a className="tiendanube-link" href={`https://www.tiendanube.com/apps/${TIENDANUBE_APP_ID}/authorize`}>
          Ingresar con Tiendanube
        </a>
      </Button>
    </Box>
  );
}
