import { useEffect, useState } from "react";
import { getScheduledDeliveriesMonthCount } from "../api";

export function useGetDeliveriesForCurrentMonthCount() {
  const [scheduledDeliveriesMonthCount, setScheduledDeliveriesMonthCount] = useState(0);
  useEffect(() => {
    getScheduledDeliveriesMonthCount()
      .then((res: {count: number}) => setScheduledDeliveriesMonthCount(res.count))
  }, [])

  return scheduledDeliveriesMonthCount;
}